.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
}

.pagination__button {
    border: none;
    padding: 0 12px;
    background-color: #FAFAFA;
    color: #002970;
    border-radius: 8px;
    height: 40px;
}

.pagination__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #222020;
    width: 30px;
    height: 32px;
    font-size: 16px;
    padding: 10px 12px;
    cursor: pointer;
    border-radius: 8px;

}

.pagination__item.active {
    color: #fff !important;
    background-color: #ED1C24 !important;
    border-radius: 8px !important;
}