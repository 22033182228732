.table {
  @apply w-full table-auto;
  &.no-border thead tr th,
  &.no-border tbody tr td {
    @apply border-0 border-none;
  }
  thead tr th {
    @apply py-3 pl-4 border-b text-xs leading-4 font-medium uppercase tracking-wider;
  }
  tbody tr td {
    @apply py-3 pl-4 border-b whitespace-no-wrap;
  }
  &.table-sm {
    thead tr th {
      @apply px-1 py-0;
    }
    tbody tr td {
      @apply px-1 py-0;
    }
  }
  &.table-lg {
    thead tr th {
      @apply px-6 py-3;
    }
    tbody tr td {
      @apply px-6 py-3;
    }
  }
}

.table {
  @apply text-left;
  thead tr th,
  tbody tr td {
    @apply text-center;
  }
}

[data-background="light"] {
  .table {
    thead tr th {
      @apply border-grey-200;
      color: #313131;
      font-size: 14px;
      font-weight: 500;
    }
    tbody tr td {
      border-color: color(#e8e8e8);
    }
    &.striped {
      tbody tr:nth-child(odd) td {
        @apply bg-white;
      }
      tbody tr:nth-child(even) td {
        background: color(#fff shade(3%));
      }
    }
  }
}

[data-background="dark"] {
  .table {
    thead tr th {
      border-color: color(theme("colors.grey.900") tint(10%));
      @apply text-grey-500;
    }
    tbody tr td {
      border-color: color(theme("colors.grey.900") tint(10%));
    }
    &.striped {
      thead tr th {
        background: color(theme("colors.grey.900") tint(6%));
        border-color: color(theme("colors.grey.900") tint(6%));
      }
      tbody tr:nth-child(odd) td {
        background: color(theme("colors.grey.900") tint(6%));
        border-color: color(theme("colors.grey.900") tint(6%));
      }
      tbody tr:nth-child(even) td {
        background: color(theme("colors.grey.900") tint(3%));
        border-color: color(theme("colors.grey.900") tint(3%));
      }
    }
  }
}
