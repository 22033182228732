.config [data-tippy-root] {
  width: fit-content;
}

[data-tippy-root] {
  width: 100%;
}

.rotate {
  rotate: 180deg;
}
.icon-close {
  transition: rotate ease-in-out 0.3s;
}

.tippy-bank {
  display: none;
  cursor: pointer;
  box-shadow: 0px 8px 11px -3px rgba(0, 0, 0, 0.08);
}
.tippy-bank-cover:hover > .tippy-bank {
  display: flex;
}

.select-field-wrapper:hover > .select-field {
  display: flex;
}
.select-field {
  display: none;
}
