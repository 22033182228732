/**
 * hide scrollbars
 */
::-webkit-scrollbar {
  /* display: none; */
  /* width: 0; */
  /* width: 4px;
  display: inline-block; */
}

/* ::-webkit-scrollbar-thumb {
  background: #FFFFFF66;
  border-radius: 8px;
} */
body > iframe[style*="2147483647"] {
  display: none;
}
.disable-scrollbars {
  /* scrollbar-width: none; */
  /* Firefox */
  /* -ms-overflow-style: none; */
  /* IE 10+ */
}

/* Custom Scrollbar Layout-1 */
.custom-scrollbar-1::-webkit-scrollbar {
  width: 6px;
  display: inline-block;
}

.custom-scrollbar-1::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 8px;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  display: inline-block;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ffffff66;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #666;
}

#root {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.link {
  @apply text-blue-500;
}

.shadow-outline-red {
  box-shadow: 0 0 0 3px theme("colors.red.300");
}

.shadow-outline-green {
  box-shadow: 0 0 0 3px theme("colors.green.300");
}

.shadow-outline-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}

.shadow-outline-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}

.w-fit {
  width: fit-content;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.logo-atom {
  background: url(`${process.env.PUBLIC_URL}/ATOM-Logo.png`) no-repeat 0 0 fixed;
  background-size: cover;
}

.bg-1 {
  background: url(/bg-login-1.jpg) no-repeat 0 0 fixed;
  background-size: cover;
}

.bg-login-2 {
  background: url(`${process.env.PUBLIC_URL}/bg-login-2.png`) no-repeat 0 0 fixed;
  background-size: contain;
}

.bg-login-3 {
  background: url(/bg-login-2.png) no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(theme("spacing.1") * 96);
}

.w-128 {
  width: calc(theme("spacing.1") * 128);
}

/* social media colors */
.text-facebook {
  color: #365397;
}

.text-twitter {
  color: #00a9f1;
}

.text-github {
  color: #2f2f2f;
}

.text-linkedin {
  color: #006db3;
}

.text-apple {
  color: #737373;
}

.text-google {
  color: #4285f4;
}

.text-google-plus {
  color: #e0452c;
}

.text-youtube {
  color: #ff3333;
}

.text-vimeo {
  color: #48b6ed;
}

.text-pinterest {
  color: #ce1a19;
}

.text-yelp {
  color: #c30f00;
}

.text-dribbble {
  color: #ed4584;
}

.text-amazon {
  color: #ff9700;
}

.text-skype {
  color: #00acf4;
}

.text-instagram {
  color: #396d9a;
}

.text-dropbox {
  color: #0d84de;
}

.text-flickr {
  color: #ea0066;
}

.text-tumblr {
  color: #304c68;
}

.text-foursquare {
  color: #207dc5;
}

/* loading circle */
.lds-dual-ring {
  position: absolute;
  right: 2px;
  width: 30px;
  height: 30px;
}

.lds-dual-ring:after {
  background-color: transparent;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #002970;
  border-color: #002970 transparent #002970 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.remove_ic_select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Font poppins */
@font-face {
  font-family: "poppins";
  font-weight: 400;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "poppins";
  font-weight: 500;
  src: url("../assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "poppins";
  font-weight: 600;
  src: url("../assets/fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "poppins";
  font-weight: 700;
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-SemiBold.woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Bold.woff2");
  font-weight: 700;
}

body {
  margin: 0;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Custom Toast Message */
.Toastify__toast-container {
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 0 !important;
  right: 0 !important;
}

.Toastify__toast {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 16px;
  margin: 24px;
  border: 1px solid #e6e8ec;
  max-width: 406px !important;
  height: auto !important;
  box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08);
  border-radius: 10px;
}

.Toastify__toast--success {
  border-left: 4px solid #0f9918;
}

.Toastify__toast--error {
  border-left: 4px solid #e54545;
}

.Toastify__toast-body {
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: flex-start !important;
  gap: 16px !important;
}

.Toastify__toast-icon {
  width: 32px !important;
  height: 32px !important;
  margin: 0 !important;
}

.h-content {
  height: calc(100vh - 188px);
}

.h-product {
  height: calc(100vh - 316px);
}

/* Config theme */
:root {
  --primary: transparent;
  --sidebar: transparent;
  --sidebar-hover: transparent;
  --select-hover: transparent;
  --text-primary: transparent;
  --text-secondary: transparent;
  --error: transparent;
}

.bg-primary {
  background: var(--primary);
}

.bg-sidebar {
  background: var(--sidebar);
}
.bg-sidebar-item,
.bg-sidebar-item-hover:hover {
  background: var(--sidebar-hover);
}

.bg-select-hover {
  background: var(--select-hover);
}

.select-item:hover {
  color: var(--primary);
  background: var(--select-hover);
}

.text-primary {
  color: var(--primary);
}

.text-text-primary {
  color: var(--text-primary);
}

.text-text-secondary {
  color: var(--text-secondary);
}

.text-error {
  color: var(--error);
}

.border-primary {
  border-color: var(--primary);
}

.create-form::placeholder {
  color: #8c8c8c;
  opacity: 1;
  /* Firefox */
}

.create-form {
  color: #262626;
}

.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  min-width: 450px;
  background-color: #262626;
  text-align: center;
  border-radius: 6px;
  padding: 6px 12px 6px 12px;
  position: absolute;
  z-index: 999;
  bottom: 150%;

  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.upload-file > .content {
  display: none;
}

.upload-file:hover > .content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.2);
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
}

input:checked ~ .toggle__line {
  background-color: var(--primary);
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

.toggle__line {
  transition: background 0.3s ease-in-out;
}

/* text-gradient */
.text-gradient {
  background: var(--logo, linear-gradient(36deg, #2b3db9 2.93%, #0cd6f7 102.74%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
